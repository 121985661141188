import { Button } from "@cof/plastic-components";
import React from "react";

const Buttons = () => (
  <div data-qa-id="landing-page-buttons">
    <Button
      data-qa-id="get-started-button"
      as="a"
      href="/getstarted"
      marginRight={18}
    >
      Get started
    </Button>
    <Button data-qa-id="view-documentation-button" as="a" href="/docs">
      View documentation
    </Button>
  </div>
);

export default Buttons;

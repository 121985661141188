import { Button, DocumentIcon, Input } from "@cof/plastic-components";
import PropTypes from "prop-types";
import React from "react";
import styles from "./InputWithCopy.module.css";

const InputWithCopy = ({ apiKey }) => {
  const copyKeyToClipboard = () => {
    navigator.clipboard.writeText(apiKey);
  };
  return (
    <div className={styles.container} data-qa-id="content-key-container">
      <Input
        className={styles.input}
        placeholder={apiKey}
        data-qa-id="key-input"
        value={apiKey}
        readOnly
      />
      <Button
        className={styles.button}
        onClick={copyKeyToClipboard}
        data-qa-id="copy-button"
      >
        <DocumentIcon />
      </Button>
    </div>
  );
};
InputWithCopy.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
export default InputWithCopy;

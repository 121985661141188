import React from "react";
import { Accordion, AccordionItem, Paragraph } from "@cof/plastic-components";

export default function FaqAccordionGroup() {
  const itemsLight = [
    <AccordionItem
      key="q1"
      heading="Do I need any previous programming experience?"
      variant="light"
      borderBottomColor="#27314A !important"
    >
      <Paragraph variant="light">
        No previous programming experience is expected! Our guides are here to
        help you understand the necessary information required from a
        foundational level.
      </Paragraph>
    </AccordionItem>,
  ];

  return <Accordion variant="mid" items={itemsLight} paddingBottom={50} />;
}

import { Heading, Paragraph } from "@cof/plastic-components";
import React from "react";

const IntroText = () => {
  const intro =
    "Welcome to Capital One's Hackathon Portal. Here you'll find all the tools you need for prototyping" +
    " and testing. Accessible for everyone: developers, students and partners.";

  return (
    <div data-qa-id="intro-text">
      <Heading
        data-qa-id="landing-page-title"
        color="shade1"
        textAlign="left"
        paddingBottom={24}
      >
        Hackathon Portal
      </Heading>
      <Paragraph
        data-qa-id="intro-paragraph"
        variant="light"
        size="large"
        marginBottom={24}
      >
        {intro}
      </Paragraph>
      <Paragraph data-qa-id="new-here-text" variant="light">
        New here?
      </Paragraph>
    </div>
  );
};

export default IntroText;

import React from "react";
import { Flex } from "@cof/plastic-components";
import PropTypes from "prop-types";
import CentredTitle from "../CentredTitle";

function TopicGroup({ title, children }) {
  return (
    <>
      <CentredTitle
        data-qa-id="learninghub-page-heading"
        title={title}
        level="2"
      />
      <Flex flexWrap="wrap" data-qa-id="three-by-three">
        {children}
      </Flex>
    </>
  );
}

TopicGroup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default TopicGroup;

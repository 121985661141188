import React from "react";
import { ApplicationLayout, PageRowFullHeight } from "@cof/plastic-components";
import FeedbackForm from "../components/FeedbackForm";
import CenteredTitle from "../components/CentredTitle";
import HeaderFlex from "../components/Template/HeaderFlex";
import Footer from "../components/Template/Footer";

function Feedback() {
  return (
    <ApplicationLayout Header={HeaderFlex} Footer={Footer}>
      <CenteredTitle data-qa-id="feedback-page-heading" title="Feedback" />
      <PageRowFullHeight wrapperBg="primary3">
        <div data-qa-id="feedback-page">
          <FeedbackForm />
        </div>
      </PageRowFullHeight>
    </ApplicationLayout>
  );
}

export default Feedback;

import React from "react";
import IndexPageFlow from "../components/IndexPageFlow";
import Template from "../components/Template/Template";
import TitleWithText from "../components/TitleWithText";
import { getGithubUrl } from "../lib/apiUtils";

function GetStarted() {
  const title = "Let's kick things off";
  const body = `The Hackathon Portal gives you a dummy account and trasaction data. 
    What you do with the data is up to you!`;
  const githubUrl = getGithubUrl();
  return (
    <Template>
      <div data-qa-id="get-started-page">
        <TitleWithText title={title} body={body} />
        <IndexPageFlow githubUrl={githubUrl} />
      </div>
    </Template>
  );
}

export default GetStarted;

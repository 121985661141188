import {
  ChatBubbleIcon,
  InlineFlex,
  LightBulbIcon,
  Link,
  Paragraph,
  UserCircledIcon,
  WebServicingIcon,
} from "@cof/plastic-components";
import React from "react";
import styles from "./LandingPage.module.css";

const Links = () => (
  <div data-qa-id="landing-page-links">
    <Paragraph
      data-qa-id="profile-link-component"
      variant="light"
      whiteSpace="pre"
      marginTop={24}
    >
      <InlineFlex alignItems="center" flexWrap="wrap">
        <UserCircledIcon marginRight={18} />
        <span className={styles.spanWrapper}>
          Returning user?{" "}
          <Link variant="light" href="/profile" data-qa-id="profile-link">
            Go to your profile
          </Link>
        </span>
      </InlineFlex>
    </Paragraph>
    <Paragraph
      data-qa-id="email-link"
      variant="light"
      whiteSpace="pre"
      marginTop={24}
    >
      <InlineFlex alignItems="center" flexWrap="wrap">
        <ChatBubbleIcon marginRight={18} />
        <span className={styles.spanWrapper}>
          Got an idea or noticed something that needs fixing?{" "}
          <Link
            href="mailto:ukdeveloperapi@capitalone.com"
            variant="light"
            data-qa-id="mail-link"
          >
            Get in touch
          </Link>
        </span>
      </InlineFlex>
    </Paragraph>
    <Paragraph
      data-qa-id="apis-link"
      variant="light"
      whiteSpace="pre"
      marginTop={24}
    >
      <InlineFlex alignItems="center" flexWrap="wrap">
        <WebServicingIcon marginRight={18} />
        <span className={styles.spanWrapper}>
          For other APIs and more, go to{" "}
          <Link
            href="http://developer.capitalone.co.uk"
            variant="light"
            data-qa-id="developer-link"
          >
            developer.capitalone.co.uk
          </Link>
        </span>
      </InlineFlex>
    </Paragraph>
    <Paragraph
      data-qa-id="learninghub-link-componenet"
      variant="light"
      marginTop={24}
    >
      <InlineFlex alignItems="center" flexWrap="wrap">
        <div className={styles.refresherWrapper}>
          <LightBulbIcon marginRight={18} minWidth={24} />
          <span className={styles.spanWrapper}>
            If you&apos;re new here or just need a quick refresher, we have
            loads of helpful info.&nbsp;{" "}
            <Link
              href="/learninghub"
              variant="light"
              data-qa-id="learninghub-link"
            >
              Learn more
            </Link>
          </span>
        </div>
      </InlineFlex>
    </Paragraph>
  </div>
);

export default Links;

import React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  capitalOneTheme,
  globalStyles,
  styleReset,
} from "@cof/plastic-components";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { createServer } from "miragejs";
import Routes from "./Routes";
import mockRoutes from "./mocks";
import config from "./lib/config";
import { getBaseUrl } from "./lib/apiUtils";

const GlobalStyle = createGlobalStyle`
    ${styleReset}
    ${globalStyles}
`;

const { enableMockServer } = config.get("mocks");
if (enableMockServer) {
  const server = createServer();
  server.logging = true;
  server.urlPrefix = getBaseUrl();
  mockRoutes(server);
}

function App() {
  return (
    <ThemeProvider theme={capitalOneTheme}>
      <GlobalStyle />
      <div id="routes" data-qa-id="routes">
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { Heading, PageRow, THEME_CONSTANTS } from "@cof/plastic-components";
import PropTypes from "prop-types";
import TopicSubtitle from "./TopicSubtitle";

const TopicCenteredTitleWithText = ({ title, subtitle }) => {
  const { COLOR_NAMES } = THEME_CONSTANTS;
  return (
    <PageRow
      contentBg={COLOR_NAMES.PRIMARY_1}
      wrapperBg={COLOR_NAMES.PRIMARY_1}
    >
      <Heading color="shade1" level="1" data-qa-id="topicpage-title">
        {title}
      </Heading>
      <TopicSubtitle subtitle={subtitle} />
    </PageRow>
  );
};

TopicCenteredTitleWithText.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default TopicCenteredTitleWithText;

import React from "react";
import { BrandedFooter } from "@cof/plastic-components";

const Footer = () => (
  <div data-qa-id="page-footer">
    <BrandedFooter />
  </div>
);

export default Footer;

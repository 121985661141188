import React from "react";
import {
  Header as PlasticHeader,
  THEME_CONSTANTS,
  CapitalOneLogoPrimaryLight,
  Flex,
  Box,
} from "@cof/plastic-components";
import RouterLink from "../RouterLink";

const HeaderFlex = () => {
  const { COLOR_NAMES } = THEME_CONSTANTS;
  return (
    <div data-qa-id="page-header-flex">
      <Flex bg="primary1">
        <Box p={["xs", null, "sm"]} width={[1, null, 2 / 3]}>
          <PlasticHeader bg={[COLOR_NAMES.PRIMARY_1]}>
            <RouterLink to="/" data-qa-id="logo-link">
              <CapitalOneLogoPrimaryLight aria-label="Route to landing page" />
            </RouterLink>
          </PlasticHeader>
        </Box>
      </Flex>
    </div>
  );
};

export default HeaderFlex;

import React from "react";
import {
  PageRow,
  THEME_CONSTANTS,
  Heading,
  Paragraph,
} from "@cof/plastic-components";
import PropTypes from "prop-types";

function TitleWithText({ title, body }) {
  const { COLOR_NAMES } = THEME_CONSTANTS;

  return (
    <PageRow
      contentBg={COLOR_NAMES.PRIMARY_1}
      wrapperBg={COLOR_NAMES.PRIMARY_1}
    >
      <Heading
        color="shade1"
        level="1"
        textAlign="left"
        data-qa-id="index-page-heading"
      >
        {title}
      </Heading>
      <Paragraph size="small" variant="light" data-qa-id="index-page-paragraph">
        {body}
      </Paragraph>
    </PageRow>
  );
}

TitleWithText.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
};
export default TitleWithText;

import { LightBulbIcon } from "@cof/plastic-components";
import React from "react";
import LineWithIcon from "./LineWithIcon";

function HackathonLine() {
  return (
    <div data-qa-id="hackathon-line">
      <LineWithIcon icon={<LightBulbIcon />}>
        Go back to&nbsp;
        <a href="/">Hackathon API</a>
      </LineWithIcon>
    </div>
  );
}
export default HackathonLine;

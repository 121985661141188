import React from "react";
import PropTypes from "prop-types";
import { Flow, FlowPoint, PageRow, Button } from "@cof/plastic-components";
import "./IndexPageFlow.css";
import RouterLink from "./RouterLink";
import ExternalLink from "./ExternalLink";

const IndexPageFlow = ({ githubUrl }) => (
  <div data-qa-id="index-page-flow">
    <PageRow wrapperBg="primary1">
      <Flow id="flow-bg">
        <FlowPoint
          title="Sign in to GitHub"
          id="flow-angle"
          data-qa-id="sign-in-section"
        >
          Click{" "}
          <Button
            data-qa-id="github-login-link"
            variant="textLinkInline"
            as="a"
            href={githubUrl}
          >
            {" "}
            here to sign in.{" "}
          </Button>{" "}
          If you don&apos;t have a GitHub account, you can{" "}
          <Button
            data-qa-id="github-sign-up-link"
            variant="textLinkInline"
            as="a"
            href="https://github.com/join"
          >
            sign up for free.
          </Button>
        </FlowPoint>
        <FlowPoint
          title="Use passphrase"
          id="flow-angle"
          data-qa-id="passphrase-section"
        >
          You&apos;ll get this at the hackathon. It lasts for the duration of
          the event.
        </FlowPoint>
        <FlowPoint
          title="Receive API key"
          id="flow-angle"
          data-qa-id="API-key-section"
        >
          You can find this on the{" "}
          <RouterLink to="/profile" data-qa-id="profile-link">
            profile page.
          </RouterLink>
        </FlowPoint>
        <FlowPoint
          title="Start developing!"
          id="flow-angle"
          data-qa-id="developing-section"
        >
          Learn about how to use our API on our{" "}
          <ExternalLink data-qa-id="docs-link" to="/docs">
            documentation page.
          </ExternalLink>
        </FlowPoint>
      </Flow>
    </PageRow>
  </div>
);

IndexPageFlow.propTypes = {
  githubUrl: PropTypes.string,
};

IndexPageFlow.defaultProps = {
  githubUrl: "/",
};

export default IndexPageFlow;

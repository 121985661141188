import React from "react";
import {
  BackButton,
  Heading,
  PageRow,
  Paragraph,
} from "@cof/plastic-components";
import Template from "../components/Template/Template";
import TopicCard from "../components/LearningHub/TopicCard";
import TopicGroup from "../components/LearningHub/TopicGroup";
import FaqAccordionGroup from "../components/LearningHub/FaqAccordionGroup";

function LearningHub() {
  return (
    <Template>
      <div data-qa-id="learninghub-page">
        <TopicGroup title="Key things to know">
          <TopicCard
            title="What is an API?"
            description="Dive deep into what an API is and how it functions"
            href="/guide/what-is-an-api"
          />
          <TopicCard
            title="What is JSON?"
            description="Find out what exactly JSON is and how it's used"
            href="/guide/what-is-json"
          />
        </TopicGroup>

        <div data-qa-id="learninghub-faq-group">
          <PageRow data-qa-id="faq-title-row">
            <Heading color="shade1" level="2" data-qa-id="faq-title">
              Common Questions
            </Heading>
            <Paragraph
              size="large"
              variant="light"
              textAlign="center"
              data-qa-id="faq-sub-title"
            >
              Answers to what you&apos;re thinking about right now
            </Paragraph>
          </PageRow>

          <FaqAccordionGroup />
        </div>

        <BackButton href="/" variant="light" data-qa-id="link-landing-page" />
      </div>
    </Template>
  );
}

export default LearningHub;

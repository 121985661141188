import React from "react";
import { Heading, Paragraph, Link } from "@cof/plastic-components";
import TopicPage from "../TopicPage";

export default function WhatIsJSON() {
  const codeExample = '{ "firstName": "Samuel", "surname": "Lee", "age": 21 }';
  const employeesExample = `    {"caponeEmployees" : [
            {"firstName": "Samuel", "surname": "Lee", "age": 21},
            {"firstName": "Matthew", "surname": "Long", "age": 27},
            {"firstName": "Timothy", "surname": "Dom", "age": 35}
        ]
    }`;
  return (
    <TopicPage
      backLink="/learninghub"
      subtitle="Find out what exactly JSON is and how it's used"
      title="What is JSON?"
      nextLink="/learninghub"
      nextTitle="Learning Hub"
    >
      <Heading level="2">The term JSON</Heading>
      <Paragraph>
        JSON stands for JavaScript Object Notation. It is a data-interchange
        format that is easy for humans to read and also simple for machines to
        parse. It is most-commonly used to exchange structured data between a
        server and a client (an example of a client is a web-app or mobile-app).
        Even though JSON has a similar style to a javascript object, JSON can be
        used independently from javascript and is used in a myriad of different
        programming environments as most languages are able to parse and
        generate JSON.
      </Paragraph>
      <Heading level="2">How is JSON structured?</Heading>
      <Paragraph>
        JSON is structured as a collection of key-value pairs. Keys are strings
        whereas values can be strings, arrays, numbers, booleans or nested JSON
        objects. The key and values are usually separated by a colon. The entire
        JSON object is wrapped around by curly braces. Here&apos;s an example of
        a simple JSON object representing a person with their first name,
        surname and age...
      </Paragraph>
      <code
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "18px",
          paddingTop: 10,
          paddingBottom: 10,
          whiteSpace: "pre",
        }}
      >
        {codeExample}
      </code>
      <Heading level="2">How is JSON used?</Heading>
      <Paragraph>
        JSON is most-commonly used to exchange structured data between client
        and server. So for example, a client may send a&nbsp;
        <Link href="/guide/what-is-an-api">GET request</Link>
        &nbsp; to the server requesting data. For the sake of this example
        let&apos;s assume the data the client is requesting is information on
        three Capital One employees. The server has access to this data but
        needs to represent it in a way that can be easily transmitted to the
        client. This is where JSON comes in! So the server can represent the
        data for the three employees in JSON and then send it to the client as a
        response to the&nbsp;
        <Link href="/guide/what-is-an-api">GET request.</Link>
        &nbsp;The response could look something like this...
      </Paragraph>
      <code>
        <pre
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          {employeesExample}
        </pre>
      </code>
      <Paragraph>
        So breaking the response down, we have a main JSON object which has the
        string caponeEmployees as a key and an array of JSON objects as its
        value. Within the value array there are three JSON objects, each of
        these objects represents an employee and each employee object has data
        about their first name, surname and age.
      </Paragraph>
    </TopicPage>
  );
}

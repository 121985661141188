import { ApplicationLayout, PageRowFullHeight } from "@cof/plastic-components";
import PropTypes from "prop-types";
import React from "react";
import HeaderFlex from "./HeaderFlex";
import Footer from "./Footer";

const Template = ({ children, background }) => (
  <div data-qa-id="global-template" id="global-template">
    <ApplicationLayout Header={HeaderFlex} Footer={Footer}>
      <PageRowFullHeight wrapperBg="primary1">
        <main data-qa-id="content-holder" style={{ background: background }}>
          {children}
        </main>
      </PageRowFullHeight>
    </ApplicationLayout>
  </div>
);

Template.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
};

Template.defaultProps = {
  background: "",
};

export default Template;

import React from "react";
import {
  IconSummaryPanelList,
  IconSummaryPanel,
  Text,
  DocumentIcon,
  PageRow,
  Button,
  ThumbsUpIcon,
} from "@cof/plastic-components";
import styles from "./DevelopmentFeedbackTile.module.css";

function DevelopmentFeedbackTile() {
  return (
    <PageRow>
      <div className={styles.panelWrapper}>
        <IconSummaryPanelList>
          <IconSummaryPanel
            Icon={DocumentIcon}
            colorVariant="dark"
            heading="Start developing"
            data-qa-id="development-panel"
            className={styles.panel}
          >
            <div className={styles.panelText}>
              <Text size="small">
                You&apos;re all set up! Copy your API key and head over to the
                documentation to get started.
              </Text>
            </div>
            <Button data-qa-id="documentation-button" as="a" href="/docs">
              View documentation
            </Button>
          </IconSummaryPanel>
          <IconSummaryPanel
            Icon={ThumbsUpIcon}
            colorVariant="dark"
            heading="Feedback"
            data-qa-id="feedback-panel"
            className={styles.panel}
          >
            <div className={styles.panelText}>
              <Text size="small">
                Developers like you keep this platform running, we find your
                feedback extremely valuable.
              </Text>
            </div>
            <Button data-qa-id="feedback-button" as="a" href="/feedback">
              Leave feedback
            </Button>
          </IconSummaryPanel>
        </IconSummaryPanelList>
      </div>
    </PageRow>
  );
}

export default DevelopmentFeedbackTile;

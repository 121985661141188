import {
  Label,
  Input,
  PageRow,
  Radio,
  Text,
  Box,
  RadioGroup,
  Button,
  HelperText,
  Modal,
  CheckCircledIcon,
  ErrorIcon,
} from "@cof/plastic-components";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import styles from "./FeedbackForm.module.css";
import RouterLink from "./RouterLink";
import submitFeedbackPostRequest from "../hooks/feedbackHook";

const CustomSuccessIcon = styled(CheckCircledIcon)`
  color: #008140;
  height: 5rem;
  width: 5rem;
`;
const CustomErrorIcon = styled(ErrorIcon)`
  color: #cc2427;
  height: 5rem;
  width: 5rem;
`;

function FeedbackForm() {
  const [hackathon, setHackathon] = useState("");
  const [positives, setPositives] = useState("");
  const [featuresUsed, setFeaturesUsed] = useState("");
  const [improvements, setImprovements] = useState("");
  const [square, setSquare] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [onSuccess, setOnSuccess] = useState(undefined);

  const successMessage = "Feedback Submitted Successfully";
  const errorMessage = "Submission Failed";
  const waitingMessage = "Feedback Processing...";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUseNativeValidation: false,
  });

  const onSubmitHandler = () => {
    const feedback = {
      hackathon: hackathon.trim(),
      positives: positives.trim(),
      featuresUsed: featuresUsed.trim(),
      improvements: improvements.trim(),
      easeOfUse: square,
    };
    setOpen(true);
    submitFeedbackPostRequest(feedback)
      .then(() => setOnSuccess(true))
      .catch(() => setOnSuccess(false));
  };
  return (
    <PageRow>
      <Modal
        data-qa-id="popup-box"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        rootId="root"
      >
        <div className={styles.customFlexBox}>
          {onSuccess === undefined ? (
            <></>
          ) : onSuccess ? (
            <CustomSuccessIcon data-qa-id="success_icon" />
          ) : (
            <CustomErrorIcon data-qa-id="error_icon" />
          )}
          <Text data-qa-id="popup-box-text">
            {onSuccess === undefined
              ? waitingMessage
              : onSuccess
              ? successMessage
              : errorMessage}
          </Text>
        </div>
      </Modal>
      <form
        className={styles.container}
        onSubmit={handleSubmit(onSubmitHandler)}
        data-qa-id="Feedback-form"
      >
        <RouterLink data-qa-id="profile-link" to="/profile">
          &lt; Back to profile page
        </RouterLink>
        <Label data-qa-id="question1">
          Which Hackathon did you attend?
          <Input
            data-qa-id="input1"
            className={styles.input}
            {...register("hackathon", {
              required: {
                value: true,
                message: "Please input an answer",
              },
              maxLength: {
                value: 500,
                message: "You can write at most 500 characters",
              },
              validate: (value) =>
                value.trim().length >= 1 || "Please input an answer",
              onChange: (e) => setHackathon(e.target.value),
              value: hackathon,
            })}
            variant={errors.hackathon?.message ? "error" : undefined}
          />
          {errors.hackathon?.message && (
            <HelperText data-qa-id="error1" variant="error">
              {errors.hackathon.message}
            </HelperText>
          )}
        </Label>
        <Label data-qa-id="question2">
          What did you like about the Developer API?
          <Input
            data-qa-id="input2"
            className={styles.input}
            {...register("positives", {
              required: {
                value: true,
                message: "Please input an answer",
              },
              maxLength: {
                value: 500,
                message: "You can write at most 500 characters",
              },
              validate: (value) =>
                value.trim().length >= 1 || "Please input an answer",
              onChange: (e) => setPositives(e.target.value),
              value: positives,
            })}
            variant={errors.positives?.message ? "error" : undefined}
          />
          {errors.positives?.message && (
            <HelperText data-qa-id="error2" variant="error">
              {errors.positives.message}
            </HelperText>
          )}
        </Label>
        <Label data-qa-id="question3">
          What features of the Developer API did you use in your project?
          <Input
            data-qa-id="input3"
            className={styles.input}
            {...register("featuresUsed", {
              required: {
                value: true,
                message: "Please input an answer",
              },
              maxLength: {
                value: 500,
                message: "You can write at most 500 characters",
              },
              validate: (value) =>
                value.trim().length >= 1 || "Please input an answer",
              onChange: (e) => setFeaturesUsed(e.target.value),
              value: featuresUsed,
            })}
            variant={errors.featuresUsed?.message ? "error" : undefined}
          />
          {errors.featuresUsed?.message && (
            <HelperText data-qa-id="error3" variant="error">
              {errors.featuresUsed.message}
            </HelperText>
          )}
        </Label>
        <Label data-qa-id="question4">
          How would you improve the Developer API? What new functionality would
          you add?
          <Input
            data-qa-id="input4"
            className={styles.input}
            {...register("improvements", {
              required: {
                value: true,
                message: "Please input an answer",
              },
              maxLength: {
                value: 500,
                message: "You can write at most 500 characters",
              },
              validate: (value) =>
                value.trim().length >= 1 || "Please input an answer",
              onChange: (e) => setImprovements(e.target.value),
              value: improvements,
            })}
            variant={errors.improvements?.message ? "error" : undefined}
          />
          {errors.improvements?.message && (
            <HelperText data-qa-id="error4" variant="error">
              {errors.improvements.message}
            </HelperText>
          )}
        </Label>
        <Label data-qa-id="question5">
          How easy was the Developer API to use? (1 being most difficult, 5
          being most easy)
        </Label>
        <Box data-qa-id="rating-buttons" className={styles.radioButtonWrapper}>
          <RadioGroup
            name="square"
            variant="square"
            {...register("square", {
              required: {
                value: true,
                message: "Please select an answer",
              },
            })}
          >
            {["1", "2", "3", "4", "5"].map((num, index) => (
              <Radio
                value={num}
                key={index}
                {...register("square", {
                  onChange: (e) => setSquare(e.target.value),
                })}
              >
                <Text>{num}</Text>
              </Radio>
            ))}
          </RadioGroup>
          {errors.square?.message && (
            <HelperText data-qa-id="error5" variant="error">
              {errors.square.message}
            </HelperText>
          )}
        </Box>
        <div className={styles.submitButton}>
          <Button data-qa-id="submit-button" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </PageRow>
  );
}

export default FeedbackForm;

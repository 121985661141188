import React from "react";
import DeveloperLine from "../components/ErrorPage/DeveloperLine";
import GetInTouchLine from "../components/ErrorPage/GetInTouchLine";
import HackathonLine from "../components/ErrorPage/HackathonLine";
import Template from "../components/Template/Template";
import TitleWithText from "../components/TitleWithText";

function ErrorPage() {
  const title = "Oops! Something went wrong";
  const body = "Let's get you back on track";

  return (
    <Template>
      <div data-qa-id="error-page">
        <TitleWithText title={title} body={body} />
        <HackathonLine />
        <DeveloperLine />
        <GetInTouchLine />
      </div>
    </Template>
  );
}

export default ErrorPage;

import React from "react";
import { PageRow, THEME_CONSTANTS, Paragraph } from "@cof/plastic-components";
import PropTypes from "prop-types";
import styled from "styled-components";
import styles from "./LineWithIcon.module.css";

const StyledIconDiv = styled.div`
  display: flex;
  color: #ffffff;
  column-gap: 1.2rem;
`;

const StyledParagraph = styled(Paragraph)`
  position: relative;
  top: 0.2rem;
`;

function LineWithIcon({ icon, children }) {
  const { COLOR_NAMES } = THEME_CONSTANTS;

  return (
    <PageRow
      contentBg={COLOR_NAMES.PRIMARY_1}
      wrapperBg={COLOR_NAMES.PRIMARY_1}
      className={styles.pageRow}
    >
      <StyledIconDiv>
        {icon}
        <StyledParagraph
          size="small"
          variant="light"
          data-qa-id="line-with-icon"
          className={styles.line}
        >
          {children}
        </StyledParagraph>
      </StyledIconDiv>
    </PageRow>
  );
}

LineWithIcon.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
export default LineWithIcon;

const config = {
  appDynamics: {
    isEnabled: false,
  },
  orchestration: {
    scheme: "https",
    host: "dev-svc-orch-np.clouddqt.uk.capitalone.com",
    port: 443,
  },
  githubCredentials: {
    baseUrl: "https://www.github.com/login/oauth/authorize",
    gitId: "d4869e2356678cbafe2e",
    redirect:
      "https://dev-svc-orch-np.clouddqt.uk.capitalone.com/auth/github/callback",
  },
  feedback: {
    baseUrl: "https://developer-web-dev.clouddqt.uk.capitalone.com",
  },
  mocks: {
    enableMockServer: false,
  },
};

export default config;

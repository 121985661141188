import {
  ApplicationLayout,
  Box,
  Flex,
  PageRowFullHeight,
} from "@cof/plastic-components";
import React from "react";
import styles from "../components/LandingPage/LandingPage.module.css";
import HeaderFlex from "../components/Template/HeaderFlex";
import IntroText from "../components/LandingPage/IntroText";
import Buttons from "../components/LandingPage/Buttons";
import Links from "../components/LandingPage/Links";

function LandingPage() {
  return (
    <ApplicationLayout Header={HeaderFlex}>
      <div data-qa-id="landing-page" className={styles.divWrapper}>
        <Flex flexWrap="wrap" height="100%">
          <Box
            p={["xs", null, "sm"]}
            width={[1, null, 2 / 3]}
            backgroundColor="primary1"
          >
            <PageRowFullHeight wrapperBg="primary1">
              <IntroText />
              <Buttons />
              <Links />
            </PageRowFullHeight>
          </Box>
          <Box
            p={["xs", null, "sm"]}
            width={[1, null, 1 / 3]}
            className={styles.pageWrapper}
          />
        </Flex>
      </div>
    </ApplicationLayout>
  );
}

export default LandingPage;

import { Paragraph } from "@cof/plastic-components";
import PropTypes from "prop-types";
import React from "react";

const TopicSubtitle = ({ subtitle, ...otherProps }) => (
  <Paragraph
    size="small"
    variant="light"
    otherProps={otherProps}
    textAlign="center"
    data-qa-id="topicpage-subtitle"
  >
    {subtitle}
  </Paragraph>
);
TopicSubtitle.propTypes = {
  subtitle: PropTypes.string.isRequired,
};
export default TopicSubtitle;

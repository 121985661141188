import axios from "axios";
import { importSPKI, jwtVerify } from "jose";
import { getBaseUrl } from "../lib/apiUtils";
import getAuthKey from "./keyHook";

const verifyToken = async (token, key) => {
  const spki = await importSPKI(key, "RS256");
  return jwtVerify(token, spki);
};

export async function decodeJWT(jwt, keys) {
  const key1 = keys.signingKeyV1;
  const key2 = keys.signingKeyV2;
  let verifiedJWT;
  try {
    verifiedJWT = await verifyToken(jwt, key1);
  } catch (e) {
    console.log(e);
  }
  try {
    verifiedJWT = await verifyToken(jwt, key2);
  } catch (e) {
    console.log(e);
  }

  if (!verifiedJWT) {
    return "unable to verify JWT";
  }
  const decodedPayload = verifiedJWT.payload;
  return {
    profileImageLink: decodedPayload.profile_image_link,
    profileName: decodedPayload.profile_name,
    developerID: decodedPayload.developer_id,
  };
}

async function axiosGetRequest() {
  const fullUrl = `${getBaseUrl()}/auth/userinfo`;
  const keys = await getAuthKey;

  try {
    const response = await axios({
      url: fullUrl,
      method: "GET",
      withCredentials: true,
    });
    if (response.status === 200) {
      const jwt = response.userJWT;
      return decodeJWT(jwt, keys);
    }
    throw new Error(
      `Network response was not ok. Status: ${response.status}, Message: ${response.statusText}`,
    );
  } catch (e) {
    console.log(e);
  }
  return "undefined";
}
export default axiosGetRequest;

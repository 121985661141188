import axios from "axios";
import { getBaseUrl } from "../lib/apiUtils";

async function getAuthKey() {
  const fullUrl = `${getBaseUrl()}/key`;
  try {
    const response = await axios({
      url: fullUrl,
      method: "GET",
      withCredentials: true,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(
      `Network response was not ok. Status: ${response.status}, Message: ${response.statusText}`,
    );
  } catch (e) {
    console.log(e);
  }
  return "undefined";
}
export default getAuthKey;

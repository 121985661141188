import { Heading, PageRow } from "@cof/plastic-components";
import PropTypes from "prop-types";
import React from "react";

const CentredTitle = ({ title, ...otherProps }) => (
  <PageRow wrapperBg="primary1">
    <Heading
      color="shade1"
      level="1"
      data-qa-id="centered-title"
      {...otherProps}
    >
      {title}
    </Heading>
  </PageRow>
);
CentredTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
export default CentredTitle;

import { Heading, PageRow } from "@cof/plastic-components";
import PropTypes from "prop-types";
import React from "react";

const ProfilePageTypography = ({ username }) => (
  <PageRow wrapperBg="primary1">
    <Heading color="shade1" level="1" data-qa-id="profile-typography">
      {username !== undefined ? `Welcome ${username}` : "Welcome!"}
    </Heading>
  </PageRow>
);
ProfilePageTypography.propTypes = {
  username: PropTypes.string,
};

ProfilePageTypography.defaultProps = {
  username: undefined,
};

export default ProfilePageTypography;

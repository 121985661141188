import React, { useState } from "react";
import { ApplicationLayout } from "@cof/plastic-components";
import HeaderFlex from "../components/Template/HeaderFlex";
import Footer from "../components/Template/Footer";
import Key from "../components/Key";
import ProfilePageTypography from "../components/ProfilePageTypography";
import DevelopmentFeedbackTile from "../components/DevelopmentFeedbackTile";
import axiosGetRequest from "../hooks/authHook";
import Passphrase from "../components/Passphrase";

function Profile() {
  const [userData, setUserData] = useState("undefined");

  try {
    axiosGetRequest().then((data) => setUserData(data.profileName));
  } catch (e) {
    console.log(e);
  }

  const [authToken, setAuthToken] = useState("Please refresh and try again");
  return (
    <ApplicationLayout Header={HeaderFlex} Footer={Footer}>
      <div data-qa-id="profile-page" style={{ backgroundColor: "#FFFFFF" }}>
        <ProfilePageTypography username={userData} />
        <Passphrase setAuthToken={setAuthToken} />
        <Key apiKey={authToken} />
        <DevelopmentFeedbackTile />
      </div>
    </ApplicationLayout>
  );
}

export default Profile;

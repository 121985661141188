import {
  Heading,
  PageRow,
  Modal,
  Button,
  Input,
  LockIcon,
} from "@cof/plastic-components";
import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Passphrase.module.css";
import getToken from "../hooks/tokenHook";
import validateFormat from "./PassphraseUtils";

function Passphrase({ setAuthToken }) {
  const [popup, showPopup] = useState(true);
  return (
    <PageRow wrapperBg="primary1">
      <Modal
        className={styles.modal}
        data-qa-id="passphrase-modal"
        isOpen={popup}
        rootId="root"
        allowDismiss={false}
        modalWrapperProps={{ width: "65rem" }}
      >
        <div className={styles.modalDiv}>
          <LockIcon
            data-qa-id="passphrase-lockIcon"
            className={styles.lockIcon}
            color="#FF8791"
            size={[25, 10, 75]}
          />
          <Heading
            className={styles.heading}
            level="3"
            data-qa-id="passphrase-heading"
          >
            Enter your Hackathon session passphrase
          </Heading>
          <Input
            id="passphraseInput"
            data-qa-id="passphrase-input"
            className={styles.input}
          />
          <Button
            mt="md"
            className={styles.button}
            data-qa-id="passphrase-button"
            onClick={async () => {
              const passphrase =
                document.getElementById("passphraseInput").value;
              if (validateFormat(passphrase)) {
                const token = await getToken(passphrase);
                if (token !== null) {
                  setAuthToken(token);
                  showPopup(false);
                } else {
                  console.log("Issue with passphrase");
                }
              }
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </PageRow>
  );
}

Passphrase.propTypes = {
  setAuthToken: PropTypes.func.isRequired,
};

export default Passphrase;

import React from "react";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import Profile from "./pages/Profile";
import GetStarted from "./pages/GetStarted";
import Feedback from "./pages/Feedback";
import LandingPage from "./pages/LandingPage";
import ErrorPage from "./pages/ErrorPage";
import LearningHub from "./pages/LearningHub";
import WhatIsAPI from "./pages/guide/WhatIsAPI";
import WhatIsJSON from "./pages/guide/WhatIsJson";

function Routes() {
  return (
    <ReactRoutes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/feedback" element={<Feedback />} />
      <Route path="/getstarted" element={<GetStarted />} />
      <Route path="/guide/what-is-an-api" element={<WhatIsAPI />} />
      <Route path="/guide/what-is-json" element={<WhatIsJSON />} />
      <Route path="/learninghub" element={<LearningHub />} />
      <Route path="*" element={<ErrorPage />} />
    </ReactRoutes>
  );
}

export default Routes;

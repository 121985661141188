import React from "react";
import {
  ApplicationLayout,
  Button,
  Flex,
  PageRowFullHeight,
} from "@cof/plastic-components";
import PropTypes from "prop-types";
import Footer from "../components/Template/Footer";
import TopicCenteredTitleWithText from "../components/TopicPage/TopicCenteredTitleWithText";
import HeaderFlex from "../components/Template/HeaderFlex";

export default function TopicPage({
  title,
  subtitle,
  backLink,
  nextLink,
  nextTitle,
  children,
}) {
  return (
    <>
      <ApplicationLayout Header={HeaderFlex} Footer={Footer}>
        <TopicCenteredTitleWithText subtitle={subtitle} title={title} />
        <PageRowFullHeight wrapperBg="primary3">
          <main data-qa-id="content-holder">
            {children}
            <Flex
              justifyContent="space-between"
              flexWrap="wrap"
              data-qa-id="responsive"
              p="xs"
            >
              <Button
                data-qa-id="back-button"
                variant="secondary"
                m="sm"
                href={backLink}
                as="a"
              >
                Back: Learning Hub
              </Button>
              <Button data-qa-id="next-button" m="sm" href={nextLink} as="a">
                Next:&nbsp;
                {nextTitle}
              </Button>
            </Flex>
          </main>
        </PageRowFullHeight>
      </ApplicationLayout>
    </>
  );
}

TopicPage.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  backLink: PropTypes.string.isRequired,
  nextLink: PropTypes.string.isRequired,
  nextTitle: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledLink = styled(Link)`
  ${({ theme, size, variant }) => css`
    ${theme.components.link.base}
    ${theme.components.link[size || "medium"]}
        ${theme.components.link[variant || "dark"]}
  `}
`;

const RouterLink = ({ to, ...otherProps }) => (
  <StyledLink to={to} {...otherProps} />
);

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default RouterLink;

import React from "react";
import {
  Heading,
  Link,
  List,
  ListItem,
  Paragraph,
  Text,
} from "@cof/plastic-components";
import TopicPage from "../TopicPage";

export default function WhatIsAPI() {
  return (
    <TopicPage
      backLink="/learninghub"
      subtitle="Dive deep into what an API is and how it functions"
      title="What is an API?"
      nextLink="/guide/what-is-json"
      nextTitle="What is JSON?"
    >
      <Heading level="2">The term API</Heading>
      <Paragraph>
        Application Programming Interface, also known as API, is a way for
        multiple computer programs to communicate with one another. This can be
        over multiple protocols and different application cases. In our case,
        we&apos;re going to be discussing REST APIs. Before we look at that, we
        need to understand about HTTP.
      </Paragraph>
      <Heading level="2">How do they work?</Heading>
      <Paragraph>
        APIs allow services and products to interact with one another, without
        having to know how they&apos;re implemented. This can simplify
        development, resulting in saved time and money. You can think of an API
        as a mediator between the users/clients and the resource/web service
        they want to get. It&apos;s also a way for organisations, like Capital
        One, to share resources and information whilst maintaining security,
        control and authentication.
      </Paragraph>
      <Heading level="2">HTTP</Heading>
      <Paragraph>
        <Text fontWeight="semiBold">H</Text>
        yper
        <Text fontWeight="semiBold">T</Text>
        ext&nbsp;
        <Text fontWeight="semiBold">T</Text>
        ransfer&nbsp;
        <Text fontWeight="semiBold">P</Text>
        rotocol (HTTP) is the primary transfer protocol that a browser uses to
        interact with a web server. There are different types of requests HTTP
        can make;
      </Paragraph>
      <List>
        <ListItem>
          <Text fontWeight="semiBold">GET</Text>
          <Text>
            : Requests a document; server sends a copy of the document.
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">HEAD</Text>
          <Text>
            : Request status information; server sends status (headers) only for
            the request document
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">POST</Text>
          <Text>
            : Sends data to a server, e.g. for form submission; server appends
            (adds) the data
          </Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">PUT</Text>
          <Text>: Also sends data server replaces the data</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">DELETE</Text>
          <Text>: Request document be deleted</Text>
        </ListItem>
      </List>
      <Paragraph>
        Various response codes can be returned from the request server;
      </Paragraph>
      <List>
        <ListItem>
          <Text fontWeight="semiBold">200</Text>
          <Text>: OK</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">400</Text>
          <Text>: Bad Request</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">403</Text>
          <Text>: Forbidden</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">404</Text>
          <Text>: Not Found</Text>
        </ListItem>
      </List>
      <Heading level="2">REST</Heading>
      <Paragraph>
        <Text fontWeight="semiBold">RE</Text>
        presentational&nbsp;
        <Text fontWeight="semiBold">S</Text>
        tate&nbsp;
        <Text fontWeight="semiBold">T</Text>
        ransfer (REST), is an API that conforms to the constraints of REST
        architectural style and allows for interaction between RESTful web
        services.
      </Paragraph>
      <List>
        <ListItem>
          <Text fontWeight="semiBold">GET&nbsp;</Text>
          <Text>= Read</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">PUT&nbsp;</Text>
          <Text>= Write</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">POST&nbsp;</Text>
          <Text>= Create/append</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">DELETE&nbsp;</Text>
          <Text>= Delete</Text>
        </ListItem>
        <ListItem>
          <Text fontWeight="semiBold">PATCH&nbsp;</Text>
          <Text>= Edit</Text>
        </ListItem>
      </List>
      <Paragraph>
        For
        <Text fontWeight="semiBold">&nbsp;GET&nbsp;</Text>
        the response body (result) is an external data representation of the
        identified resource. Depending on the resource, it can be&nbsp;
        <Link href="/guide/what-is-json">JSON</Link>
        &nbsp; or XML encoding, but this is normally specified by content-type
        headers
      </Paragraph>
      <Paragraph>
        For
        <Text fontWeight="semiBold">&nbsp;PUT/POST&nbsp;</Text>
        the only argument - the request body - is also a (new) representation of
        the identified resource
      </Paragraph>
    </TopicPage>
  );
}

import React from "react";
import {
  Box,
  Heading,
  Paragraph,
  Link,
  QuestionMarkIcon,
} from "@cof/plastic-components";
import PropTypes from "prop-types";
import styles from "./LearningHub.module.css";

function TopicCard({ title, href, description }) {
  const linkQaID = `learninghub-topic-card-link-${href.split("/").pop()}`;
  return (
    <Box
      p="xs"
      width={[1, null, 1 / 2]}
      textAlign={["left", null, "center"]}
      data-qa-id="learninghub-topic-card"
    >
      <Box className={styles.card} height={1}>
        <QuestionMarkIcon
          color="#27314a"
          className={styles.questionMark}
          data-qa-id="learninghub-topic-card-icon"
        />
        <Heading
          level="3"
          color="white"
          textAlign={["left", null, "center"]}
          data-qa-id="learninghub-topic-card-title"
        >
          {title}
        </Heading>
        <Paragraph
          alignItems="center"
          variant="light"
          data-qa-id="learninghub-topic-card-description"
        >
          {description}
        </Paragraph>
        <Link href={href} variant="light" data-qa-id={linkQaID}>
          Learn more
        </Link>
      </Box>
    </Box>
  );
}

TopicCard.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default TopicCard;

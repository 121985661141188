import PropTypes from "prop-types";

function validateFormat(passphrase) {
  if (passphrase.split("-").length === 4) {
    return true;
  }
  return false;
}

validateFormat.propTypes = {
  passphrase: PropTypes.string.isRequired,
};

export default validateFormat;

import config from "./config";

export const getBaseUrl = () => {
  const orchestration = config.get("orchestration");
  return `${orchestration.scheme}://${orchestration.host}:${orchestration.port}`;
};

export const getGithubUrl = () => {
  const { baseUrl, gitId } = config.get("githubCredentials");
  return `${baseUrl}?scope=user:email%20read:user&client_id=${gitId}`;
};

export function handleResponse(response) {
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(`Network response was not ok. Status: ${response.status}`);
}

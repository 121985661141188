import feedbackMocks from "./feedback";
import { Response } from "miragejs";

const mockRoutes = (server) => {
  server.post("/feedback", () => feedbackMocks.submit());

  server.post("/auth/token", (schema, request) => {
    if (
      request?.requestBody &&
      JSON.parse(request.requestBody)?.hackathon_code === "f-a-i-l"
    ) {
      return new Response(500, {}, {});
    }

    return new Response(200, {}, { token: "exampletoken" });
  });
};

export default mockRoutes;

import { Heading, PageRow } from "@cof/plastic-components";
import React from "react";
import PropTypes from "prop-types";
import InputWithCopy from "./InputWithCopy";

const Key = ({ apiKey }) => (
  <PageRow wrapperBg="primary1">
    <Heading color="shade1" level="2" data-qa-id="api-key-heading">
      Your API key
    </Heading>
    <InputWithCopy apiKey={apiKey} data-qa-id="api-key-content" />
  </PageRow>
);
Key.propTypes = {
  apiKey: PropTypes.string.isRequired,
};
export default Key;

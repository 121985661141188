import { AgentIcon } from "@cof/plastic-components";
import React from "react";
import LineWithIcon from "./LineWithIcon";

function GetInTouchLine() {
  return (
    <div data-qa-id="get-in-touch-line">
      <LineWithIcon icon={<AgentIcon />}>
        Still stuck?&nbsp;
        <a href="https://www.capitalone.co.uk/support/contact-us">
          Get in touch
        </a>
      </LineWithIcon>
    </div>
  );
}
export default GetInTouchLine;

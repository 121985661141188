import { OTPIcon } from "@cof/plastic-components";
import React from "react";
import LineWithIcon from "./LineWithIcon";

function DeveloperLine() {
  return (
    <div data-qa-id="developer-line">
      <LineWithIcon icon={<OTPIcon />}>
        For other APIs and more, go to&nbsp;
        <a href="http://developer.capitalone.co.uk">
          developer.capitalone.co.uk
        </a>
      </LineWithIcon>
    </div>
  );
}
export default DeveloperLine;

import axios from "axios";
import { getBaseUrl } from "../lib/apiUtils";

async function getToken(passphrase) {
  const fullUrl = `${getBaseUrl()}/auth/token`;
  try {
    const response = await axios({
      url: fullUrl,
      method: "POST",
      withCredentials: true,
      data: {
        hackathon_code: passphrase,
      },
    });

    if (response.status !== 200) {
      console.error(
        `Network response was not ok. Status: ${response.status}, Message: ${response.statusText}`,
      );
      return null;
    }

    if (!response?.data?.token) {
      console.error(
        `Network response was ok but response did not contain token.`,
      );
      return null;
    }

    return response?.data?.token;
  } catch (e) {
    console.log(e);
  }
  return null;
}

export default getToken;

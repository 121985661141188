import axios from "axios";
import { getBaseUrl } from "../lib/apiUtils";

async function submitFeedbackPostRequest(feedbackObject) {
  const fullUrl = `${getBaseUrl()}/feedback`;
  const response = await axios({
    url: fullUrl,
    method: "POST",
    withCredentials: true,
    data: feedbackObject,
  });
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(
    `Network response was not ok. Status: ${response.status}, Message: ${response.statusText}`,
  );
}
export default submitFeedbackPostRequest;
